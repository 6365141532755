<template>
  <div class="movies_more_warp">
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <svg-icon icon-class="back"></svg-icon>
      </div>
      <van-tabs
        line-width="0.6rem"
        line-height="0.08rem"
        v-model="activeName"
        speed="40"
        class="tabs"
        background="#ffffff"
        color="#ff678f"
        title-inactive-color="#666666"
        title-active-color="#ff678f"
        animated
      >
        <van-tab
          title-style="font-size:0.421rem"
          title="最新"
          name="/movies/more/new"
          :to="{
            path: '/movies/more/new',
            query: $route.query,
          }"
          replace
        >
          <div class="content">
            <KeepAlive>
              <router-view v-if="activeName == '/movies/more/new'" />
            </KeepAlive>
          </div>
        </van-tab>
        <van-tab
          title-style="font-size:0.421rem"
          title="最热"
          name="/movies/more/hot"
          :to="{
            path: '/movies/more/hot',
            query: $route.query,
          }"
          replace
        >
          <div class="content">
            <KeepAlive>
              <router-view v-if="activeName == '/movies/more/hot'" />
            </KeepAlive>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { RouterView } from 'vue-router';
export default {
  name: 'moviesMore',
  components: {
    RouterView,
  },
  data() {
    return {
      activeName: '/movies/more/new',
    };
  },
};
</script>
<style lang="scss" scoped>
.movies_more_warp {
  height: 100%;

  /deep/ .van-tabs__line {
    bottom: 0.55rem;
  }
  .header {
    height: 44px;
    background: #ffffff;
    /deep/ .van-tab {
      font-size: 16px;
      flex: unset;
      padding: 0 15px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    /deep/ .van-tabs__wrap {
      display: flex;
      justify-content: center;
    }
    .back {
      position: absolute;
      top: 6px;
      left: 20px;
      z-index: 1001;
      font-size: 24px;
      svg {
        font-size: 26px;
      }
    }

    h2 {
      font-size: 20px;
    }

    .tabs {
      font-size: 0.467rem;
    }
  }

  .content {
    padding: 5px 16px;
    box-sizing: border-box;
    height: $moviesMoreHeight;
  }
}
</style>
