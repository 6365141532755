var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"movies_more_warp"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"back"}})],1),_c('van-tabs',{staticClass:"tabs",attrs:{"line-width":"0.6rem","line-height":"0.08rem","speed":"40","background":"#ffffff","color":"#ff678f","title-inactive-color":"#666666","title-active-color":"#ff678f","animated":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('van-tab',{attrs:{"title-style":"font-size:0.421rem","title":"最新","name":"/movies/more/new","to":{
          path: '/movies/more/new',
          query: _vm.$route.query,
        },"replace":""}},[_c('div',{staticClass:"content"},[_c('KeepAlive',[(_vm.activeName == '/movies/more/new')?_c('router-view'):_vm._e()],1)],1)]),_c('van-tab',{attrs:{"title-style":"font-size:0.421rem","title":"最热","name":"/movies/more/hot","to":{
          path: '/movies/more/hot',
          query: _vm.$route.query,
        },"replace":""}},[_c('div',{staticClass:"content"},[_c('KeepAlive',[(_vm.activeName == '/movies/more/hot')?_c('router-view'):_vm._e()],1)],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }